import React, {useEffect, useState} from 'react';
import {Box, Grid, Button, TextField} from '@mui/material';
import {useSelector, useDispatch} from 'react-redux';
import {saveLogin} from 'common/reducer/auth';
import {useLocation} from 'react-router-dom';
import HeaderView from 'view/common/Header';
import APIUtils from 'common/utils/APIUtils';
import FooterView from 'view/common/Footer';
import SidebarView from 'view/mypage/SidebarView';
import FragmentHome from 'view/mypage/FragmentHome';
import FragmentAccount from 'view/mypage/FragmentAccount';
import FragmentEval from 'view/mypage/FragmentEval';
import FragmentClass from 'view/mypage/FragmentClass';
import FragmentTask from 'view/mypage/FragmentTask';
import FragmentTaskSelected from 'view/mypage/FragmentTaskSelected';
import FragmentTaskRunningSelected from 'view/mypage/FragmentTaskRunningSelected';
import FragmentLibrary from 'view/mypage/FragmentLibrary';

const MyPage = () => {
  const authReducer = useSelector(state => state.authReducer);
  const location = useLocation();
  const dispatch = useDispatch();

  const [fragmentStatus, setFragmentStatus] = useState(1);
  const [selectedTaskID, setSelectedTaskID] = useState(0);
  function showFragment() {
    switch (fragmentStatus) {
      case 1:
        return (
          <FragmentHome
            finishedTaskClickEvent={newTaskID => {
              setSelectedTaskID(newTaskID);
              setFragmentStatus(6);
            }}
            runningTaskClickEvent={newTaskID => {
              setSelectedTaskID(newTaskID);
              setFragmentStatus(7);
            }}
          />
        );
      case 2:
        return (
          <FragmentAccount
            getPassInfo={getPassInfo}
            passStatus={passStatus}
            group_name={group_name}
            user_id={user_id}
            auth_key={auth_key}
          />
        );
      case 3:
        return <FragmentEval />;
      case 4:
        return <FragmentClass />;
      case 5:
        return (
          <FragmentTask
            finishedTaskClickEvent={newTaskID => {
              setSelectedTaskID(newTaskID);
              setFragmentStatus(6);
            }}
            runningTaskClickEvent={newTaskID => {
              setSelectedTaskID(newTaskID);
              setFragmentStatus(7);
            }}
            clickEvent={SidebarClickEvent}
          />
        );
      case 6:
        return (
          <FragmentTaskSelected
            taskID={selectedTaskID}
            previousPageEvent={() => setFragmentStatus(5)}
          />
        );
      case 7:
        return (
          <FragmentTaskRunningSelected
            taskID={selectedTaskID}
            previousPageEvent={() => setFragmentStatus(5)}
          />
        );
      case 8:
        return <FragmentLibrary />;
      default:
        return <FragmentHome />;
    }
  }
  function SidebarClickEvent(option) {
    setFragmentStatus(option);
  }
  const [passStatus, setPassStatus] = useState('none');
  const [group_name, setGroupName] = useState('');
  const [auth_key, setAuthkey] = useState('');
  const [user_id, setUserID] = useState('');

  const [passEndTime, setPassEndTime] = useState('');
  const [passStudentCount, setPassStudentCount] = useState(0);

  function getPassInfo() {
    const passResult = async () => {
      try {
        const response = await APIUtils.PassCheck(authReducer.user_id);
        if (response.status == 200 && response.data.ret_code == 1000) {
          setPassStatus(response.data.status);
          setPassEndTime(response.data.end_time);
          setPassStudentCount(response.data.max_student_count);
        }
      } catch (err) {
        console.log(err);
      }
    };
    passResult();
  }
  function getLoginInfo() {
    const accountInfo = async () => {
      try {
        const response = await APIUtils.AccountInfo(authReducer.user_id);
        if (response.data.ret_code == 1000) {
          dispatch(
            saveLogin({
              user_id: response.data.user_id,
              user_name: response.data.user_name,
              user_email: response.data.email,
              export_name: response.data.export_name,
              export_email: response.data.export_email,
              grade_in_charge: response.data.grade_in_charge,
              path: authReducer.path,
              user_token: authReducer.user_token,
              group_name: response.data.group_name,
              is_group_admin: response.data.is_group_admin,
              auth_key: response.data.auth_key,
              passStatus: response.data.status,
            }),
          );
          setGroupName(response.data.group_name);
          setAuthkey(response.data.auth_key);
          setUserID(response.data.user_id);
        }
      } catch (err) {
        console.log(err);
      }
    };
    accountInfo();
  }
  useEffect(() => {
    let fragmentVal = location.state?.fragmentNum || 1;
    let selectedTaskVal = location.state?.selectedTaskID || 0;
    setFragmentStatus(fragmentVal);
    setSelectedTaskID(selectedTaskVal);
    getPassInfo();
    getLoginInfo();
  }, []);
  return (
    <Box
      style={{
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <HeaderView />
      <Box
        style={{
          marginLeft: '2%',
          marginRight: '1%',
          marginBottom: '5%',
          marginTop: '1%',
          width: '98%',
          flex: 1,
          display: 'flex',
        }}
      >
        <Box>
          <SidebarView
            currentFragment={fragmentStatus}
            passStatus={passStatus}
            passEndTime={passEndTime}
            passStudentCount={passStudentCount}
            clickEvent={SidebarClickEvent}
            getPassInfo={getPassInfo}
          />
        </Box>
        <Box
          style={{
            flex: 1,
            marginLeft: '5%',
            marginRight: '5%',
          }}
        >
          {showFragment()}
        </Box>
      </Box>
      <FooterView />
    </Box>
  );
};

export default MyPage;
