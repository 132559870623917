import React, {useState} from 'react';
import {Button, Box, Grid} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import HeaderView from 'view/common/Header';
import backgroundMain1 from 'asset/imageV2/background_main1.svg';
import backgroundMain2 from 'asset/imageV2/background_main2.png';
import imageNotebook from 'asset/imageV2/image_notebook.png';
import iconArrowRight from 'asset/imageV2/icon_arrow_right.png';
import iconDownload from 'asset/imageV2/icon_download.png';
import iconCert1 from 'asset/imageV2/icon_cert1.svg';
import iconCert2 from 'asset/imageV2/icon_cert2.png';
import iconCert3 from 'asset/imageV2/icon_cert3.png';
import iconCert4 from 'asset/imageV2/icon_cert4.png';
import iconCert5 from 'asset/imageV2/icon_cert5.png';
import iconCert6 from 'asset/imageV2/icon_cert6.png';
import imgMain1 from 'asset/imageV2/image_main1.png';
import imgMain2 from 'asset/imageV2/image_main2.png';
import imgMain3 from 'asset/imageV2/image_main3.png';
import imgMain5 from 'asset/imageV2/image_main5.png';
import samplePDF from 'asset/pdf/sample.pdf';
import {mainColoredBtn, iconBox} from 'view/main/Styles';
import FooterView from 'view/common/Footer';
import AutoSlider from 'view/common/AutoSlider';
import SideOverlay1 from 'view/common/SideOverlay1';
import SchoolView from 'view/common/SchoolView';
import APIUtils from 'common/utils/APIUtils';
import {GetRunningPopup} from 'common/utils/GraphQLUtils';

const MainPage = () => {
  const authReducer = useSelector(state => state.authReducer);
  const navigate = useNavigate();
  const [expand1, setExpand1] = useState(false);
  const [expand2, setExpand2] = useState(false);
  const [expand3, setExpand3] = useState(false);
  const [expand4, setExpand4] = useState(false);
  const [expand5, setExpand5] = useState(false);
  const [expand6, setExpand6] = useState(false);
  const [expand7, setExpand7] = useState(false);
  const [expand8, setExpand8] = useState(false);

  function downloadSamplePDF() {
    const pdfBlob = new Blob([samplePDF], {type: 'application/pdf'});
    const url = window.URL.createObjectURL(pdfBlob);
    const a = document.createElement('a');
    a.href = samplePDF;

    a.download = 'KEEwiT_sample_2025.pdf';
    document.body.appendChild(a);
    a.click();
    setTimeout(_ => {
      window.URL.revokeObjectURL(url);
    }, 60000);
    a.remove();
  }
  function showMainButton() {
    if (authReducer.isLogin) {
      return (
        <Button
          variant="standard"
          style={mainColoredBtn}
          onClick={() => {
            APIUtils.LoggerLog(
              authReducer.user_id,
              'keewit',
              'info',
              window.location.pathname,
              'navigate_mypage',
            );
            navigate('/mypage');
          }}
        >
          들어가기
          <img
            src={iconArrowRight}
            alt="iconArrowRight"
            style={{marginLeft: '1rem', width: '1.5rem'}}
          />
        </Button>
      );
    }
    return (
      <Button
        variant="standard"
        style={mainColoredBtn}
        onClick={() => {
          APIUtils.LoggerLog(
            authReducer.user_id,
            'keewit',
            'info',
            window.location.pathname,
            'navigate_login',
          );
          navigate('/login');
        }}
      >
        {'회원가입/로그인'}
        <img
          src={iconArrowRight}
          alt="iconArrowRight"
          style={{marginLeft: '1rem', width: '1.5rem'}}
        />
      </Button>
    );
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <GetRunningPopup />
      <SideOverlay1 />
      <HeaderView />
      <Box
        style={{
          width: '80%',
          maxWidth: '80rem',
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '3rem',
        }}
      >
        <Box style={{flex: 4}}>
          <Box
            style={{
              fontSize: '1rem',
              color: '#49545c',
              marginTop: '12rem',
            }}
          >
            과제 관리부터 논술 첨삭까지
          </Box>
          <Box
            style={{
              fontSize: '2.625rem',
              color: '#2f3941',
              fontWeight: '700',
              marginTop: '1rem',
            }}
          >
            글쓰기 평가
          </Box>
          <Box
            style={{fontSize: '2.625rem', color: '#2f3941', fontWeight: '700'}}
          >
            <span style={{color: '#35d2bb'}}>한번에</span> 해결하세요!
          </Box>
          <Box style={{marginTop: '2rem', marginBottom: '5rem'}}>
            {showMainButton()}
          </Box>
        </Box>
        <Box
          style={{
            flex: 6,
            position: 'relative',
          }}
        >
          <img
            src={imageNotebook}
            alt="imgnotebook"
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              objectFit: 'contain',
              objectPosition: 'center center',
            }}
          />
          <img
            src={backgroundMain1}
            alt={'bg1'}
            style={{
              width: '120%',
              height: '100%',
              zIndex: '-1',
              position: 'absolute',
              objectFit: 'contain',
              objectPosition: 'center center',
              marginLeft: '-20%',
            }}
          />
        </Box>
      </Box>

      {/* <SchoolView /> */}
      <Box
        style={{
          marginTop: '1rem',
          width: '80%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box style={{color: '#3cd9c2', fontWeight: '700'}}>수상 및 인증</Box>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            marginTop: '4rem',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box style={iconBox}>
              <img
                src={iconCert5}
                alt="iconCert1"
                style={{
                  height: '50%',
                }}
              />
            </Box>
            <Box> 국가공헌대상</Box>
            <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
              교육부 장관상
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box style={iconBox}>
              <img
                src={iconCert5}
                alt="iconCert2"
                style={{
                  height: '50%',
                }}
              />
            </Box>
            <Box>에듀테크 소프트랩</Box>
            <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
              실증 사례 우수상
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box style={iconBox}>
              <img
                src={iconCert5}
                alt="iconCert2"
                style={{
                  height: '50%',
                }}
              />
            </Box>
            <Box>이러닝-에듀테크</Box>
            <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
              비즈모델 최우수상
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box style={iconBox}>
              <img
                src={iconCert5}
                alt="iconCert3"
                style={{
                  height: '50%',
                }}
              />
            </Box>
            <Box>에듀테크 우수기업 </Box>
            <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
              콘텐츠 우수상
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box style={iconBox}>
              <img
                src={iconCert3}
                alt="iconCert4"
                style={{
                  height: '50%',
                }}
              />
            </Box>
            <Box>TTA</Box>
            <Box style={{color: '#3cc5b0', fontWeight: '700'}}>GS 1등급</Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box style={iconBox}>
              <img
                src={iconCert1}
                alt="iconCert5"
                style={{
                  height: '50%',
                }}
              />
            </Box>
            <Box>조달청 인증</Box>
            <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
              벤처혁신상품
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          marginTop: '6rem',
          width: '80%',
          display: 'flex',
          maxWidth: '80rem',
        }}
      >
        <Box
          style={{
            backgroundColor: '#f9f9f9',
            margin: '1rem 1rem',
            padding: '2.5rem 2.5rem 0rem 2.5rem',
            borderRadius: '1.25rem',
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box style={{fontSize: '1.75rem', fontWeight: 'bold'}}>
            <span style={{color: '#35d2bb'}}>표준화된</span>
          </Box>
          <Box style={{fontSize: '1.75rem', fontWeight: 'bold'}}>채점 엔진</Box>
          <Box style={{fontSize: '1.75rem', fontWeight: 'bold'}}></Box>
          <Box style={{marginTop: '3.5rem'}}>
            키위티에 사용하는{' '}
            <span style={{fontWeight: '700'}}>
              키위 AI 글쓰기 평가 결과는 정규분포
            </span>
            를 따릅니다. S교육브랜드의 2만건 데이터와 J초등학교의 400건 데이터를
            채점한 결과, 키위 AI 글쓰기 평가 모델이 표준화된 평가 기준을 따르며,
            타당도 높은 평가를 수행하고 있음이 확인되었습니다.
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'end',
              flex: 1,
            }}
          >
            <img src={imgMain1} alt="imgMain1" style={{width: '60%'}} />
          </Box>
        </Box>
        <Box
          style={{
            backgroundColor: '#f9f9f9',
            margin: '1rem 1rem',
            padding: '2.5rem 2.5rem 0rem 2.5rem',
            borderRadius: '1.25rem',
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box style={{fontSize: '1.75rem', fontWeight: 'bold'}}>
            신뢰도 높은
          </Box>
          <Box
            style={{fontSize: '1.75rem', color: '#35d2bb', fontWeight: 'bold'}}
          >
            객관적 평가
          </Box>
          <Box style={{marginTop: '3.5rem'}}>
            AI허브 에세이 평가 5만 건에 대한 평가자 간 상관도와 비교하여
            평가자와 키위 AI 간의 상관도가 더 높게 나타났습니다. 키위 AI는
            평가자의{' '}
            <span style={{fontWeight: '700'}}>
              주관성을 배제한 객관적인 평가를 수행
            </span>
            하여, 신뢰도 높은 평가를 수행합니다.
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'end',
              flex: 1,
            }}
          >
            <img src={imgMain2} alt="imgMain2" style={{width: '70%'}} />
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          width: '80%',
          maxWidth: '80rem',
          display: 'flex',
        }}
      >
        <Box
          style={{
            backgroundColor: '#f9f9f9',
            margin: '1rem 1rem',
            padding: '2.5rem 2.5rem 0rem 2.5rem',
            borderRadius: '1.25rem',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box style={{fontSize: '1.75rem', fontWeight: 'bold'}}>
            선생님-학생의
          </Box>
          <Box
            style={{fontSize: '1.75rem', color: '#35d2bb', fontWeight: 'bold'}}
          >
            글쓰기 과제 연결
          </Box>
          <Box style={{marginTop: '3.5rem'}}>
            키위런은 키위티의 학생용 서비스로 키위티와 연결되어 있습니다. 키위런
            학생과 연결하여 글쓰기 과제를 쉽게 생성하고 관리하세요. 과제가
            끝나면, 선생님의 피드백을 반영한 최종 리포트를 학생들에게 공유할 수
            있습니다.
          </Box>
          <Box>
            <span style={{fontWeight: '700'}}>간편한 글쓰기 과제</span> 관리,
            키위티와 함께라면 가능합니다.
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'end',
              flex: 1,
            }}
          >
            <img src={imgMain3} alt="imgMain3" style={{width: '70%'}} />
          </Box>
        </Box>
        <Box
          style={{
            backgroundColor: '#f9f9f9',
            margin: '1rem 1rem',
            padding: '2.5rem 2.5rem 0rem 2.5rem',
            borderRadius: '1.25rem',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <Box style={{fontSize: '1.75rem', fontWeight: 'bold'}}>
            한 눈에 보는 분석 결과
          </Box>
          <Box
            style={{fontSize: '1.75rem', color: '#35d2bb', fontWeight: 'bold'}}
          >
            전문적인 리포트
          </Box>
          <Box
            style={{
              marginTop: '3.5rem',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                // width: '14rem',
              }}
            >
              <Box>
                키위티 리포트는 한 눈에 분석 결과를 확인할 수 있습니다.{' '}
                <span style={{fontWeight: '700'}}>총평과 통계 분석</span>은
                물론,{' '}
                <span style={{fontWeight: '700'}}>
                  평가 지표에 따른 레이더 차트, 워드클라우드, 버블 차트
                </span>{' '}
                등이 결과에 출력됩니다. 글 전체와 문단별 피드백도 함께
                제공됩니다.
                <Box>(*글 장르에 따라 리포트 형태는 달라질 수 있습니다.)</Box>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '3rem',
                  marginBottom: '2rem',
                }}
              >
                <Button
                  variant="standard"
                  style={mainColoredBtn}
                  onClick={() => {
                    APIUtils.LoggerLog(
                      authReducer.user_id,
                      'keewit',
                      'info',
                      window.location.pathname,
                      'click_event_download_sample_pdf',
                    );
                    downloadSamplePDF();
                  }}
                >
                  {'리포트 예시'}
                  <img
                    src={iconDownload}
                    alt="iconDownload"
                    style={{marginLeft: '0.5rem', width: '1.5rem'}}
                  />
                </Button>
              </Box>
            </Box>
            {/* <Box
              style={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'end',
                flex: 1,
              }}
            >
              <img src={imgMain4} alt="imgMain4" style={{width: '100%'}} />
            </Box> */}
          </Box>
        </Box>
      </Box>

      <Box
        style={{
          marginTop: '6rem',
          width: '100%',
          height: '38rem',
          background: 'rgba(240, 251, 250, 0.50)',
          position: 'relative',
        }}
      >
        <img
          src={backgroundMain2}
          alt="backgroundMain2"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center top',
          }}
        />
        <Box
          style={{
            width: '100%',
            textAlign: 'center',
            fontSize: '1.75rem',
            fontWeight: '700',
            paddingTop: '6rem',
          }}
        >
          키위 평가 지표
        </Box>
        <Box
          style={{
            marginTop: '1rem',
            width: '100%',
            textAlign: 'center',
            color: '#49545C',
            letterSpacing: '-0.03rem',
          }}
        >
          키위 평가 엔진은 1960년대부터 연구된 영미권 글쓰기 평가의 기준을
          한국어 글쓰기 평가에 적용하였습니다.
        </Box>
        <Box
          style={{
            width: '100%',
            textAlign: 'center',
            color: '#49545C',
            letterSpacing: '-0.03rem',
          }}
        >
          AI의 정량적 평가 루브릭은 ‘글쓰기의 6가지 관점(6 traits)’을 바탕으로
          구성되었습니다.
        </Box>
        <Box
          style={{
            width: '100%',
            textAlign: 'center',
            color: '#49545C',
            letterSpacing: '-0.03rem',
          }}
        >
          ‘주제의 명확성’, ‘조직과 전개’, ‘어휘의 풍부성’, ‘문장 유창성’,
          ‘의도의 표현’, ‘문법의 정확성’
        </Box>
        <Box
          style={{
            width: '100%',
            textAlign: 'center',
            color: '#49545C',
            letterSpacing: '-0.03rem',
          }}
        >
          6가지 지표로 피드백을 제공합니다.
        </Box>
        <AutoSlider />
      </Box>
      <Box
        style={{
          width: '70%',
          maxWidth: '80rem',
          marginTop: '7rem',
          marginBottom: '5rem',
          display: 'flex',
        }}
      >
        <Box style={{flex: '4'}}>
          <Box style={{color: '#3cd9c2', fontWeight: '700'}}>FAQ</Box>
          <Box
            style={{
              fontSize: '1.75rem',
              fontWeight: '700',
              marginTop: '0.5rem',
            }}
          >
            자주 묻는 질문
          </Box>
          <Box style={{display: 'block'}}>
            <Button
              style={{
                display: 'block',
                marginTop: '2rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                APIUtils.LoggerLog(
                  authReducer.user_id,
                  'keewit',
                  'info',
                  window.location.pathname,
                  'click_event_expand1',
                );
                setExpand1(prev => !prev);
              }}
            >
              Q. 키위티와 키위런 차이는?
            </Button>
            <Box
              style={{
                display: expand1 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              키위티는 선생님용 서비스입니다. 글을 입력하거나 업로드하여 AI
              피드백의 도움을 받을 수 있고, 키위런(학생) 계정과 연결하여
              학생들에게 글쓰기 과제를 부여할 수 있습니다. 키위런은 학생용
              서비스로, 키위티 선생님 계정과 연결한 다음에 사용할 수 있습니다.
            </Box>
            <Button
              style={{
                display: 'block',
                marginTop: '1rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                APIUtils.LoggerLog(
                  authReducer.user_id,
                  'keewit',
                  'info',
                  window.location.pathname,
                  'click_event_expand2',
                );
                setExpand2(prev => !prev);
              }}
            >
              Q. 혼자서 키위티 사용하고 싶습니다.
            </Button>
            <Box
              style={{
                display: expand2 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              키위런(학생) 계정 없이 키위티를 사용할 수 있습니다. 평가관리에서
              +새글평가, 파일 업로드, 입력 링크를 활용해서 글을 입력하고, AI
              조교의 초벌 첨삭과 피드백을 수정해서 평가지를 완성할 수 있습니다.
            </Box>
            <Button
              style={{
                display: 'block',
                marginTop: '1rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                APIUtils.LoggerLog(
                  authReducer.user_id,
                  'keewit',
                  'info',
                  window.location.pathname,
                  'click_event_expand3',
                );
                setExpand3(prev => !prev);
              }}
            >
              Q. 자녀에게 키위런을 시키고 싶습니다.
            </Button>
            <Box
              style={{
                display: expand3 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              가정에서 키위티-키위런을 사용할 수 있습니다. 부모님이 키위티를
              자녀는 키위런을 사용합니다. 자녀에게 글쓰기 과제, 글쓰기 대회, AI
              글쓰기 연습을 통해 논술형 평가 시대를 대비하세요.
            </Box>
            <Button
              style={{
                display: 'block',
                marginTop: '1rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                APIUtils.LoggerLog(
                  authReducer.user_id,
                  'keewit',
                  'info',
                  window.location.pathname,
                  'click_event_expand4',
                );
                setExpand4(prev => !prev);
              }}
            >
              Q. 요금제 선택 기준 알려주세요.
            </Button>
            <Box
              style={{
                display: expand4 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              키위티 요금제는 개인 이용자와 그룹이용자로 나뉩니다. 사용
              기간(6개월/12개월)과 학생 수를 고려하여 선택하면 됩니다. 해당 학생
              수만큼, 키위런(학생) 계정을 인증할 수 있습니다. 공교육 기관은
              학교장터에서 직접 구매가능 합니다. 사교육 기관은 ‘카카오톡
              상담하기’를 통해 그룹 이용권의 견적을 요청해 주세요.
            </Box>
            <Button
              style={{
                display: 'block',
                marginTop: '1rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                APIUtils.LoggerLog(
                  authReducer.user_id,
                  'keewit',
                  'info',
                  window.location.pathname,
                  'click_event_expand5',
                );
                setExpand5(prev => !prev);
              }}
            >
              Q. 무료 체험 신청하고 싶어요.
            </Button>
            <Box
              style={{
                display: expand5 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              키위티 도입 전, 무료로 사용해 보고 싶으신가요?{' '}
              <span
                style={{cursor: 'pointer', textDecoration: 'underline'}}
                onClick={() => {
                  window.open('https://forms.gle/vq3jCb1tyQEywvXq6');
                }}
              >
                여기
              </span>
              를 클릭해서 신청해주세요. 1개월 이용권(선생님 1명, 학생 30명)을
              제공합니다.
            </Box>
            <Button
              style={{
                display: 'block',
                marginTop: '1rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                APIUtils.LoggerLog(
                  authReducer.user_id,
                  'keewit',
                  'info',
                  window.location.pathname,
                  'click_event_expand6',
                );
                setExpand6(prev => !prev);
              }}
            >
              Q. 문해력 더하기는 무료인가요?
            </Button>
            <Box
              style={{
                display: expand6 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              네, 무료입니다. AI가 학습하는 방법이 바로 빈칸 채우기
              콘텐츠입니다. 선생님의 좋은 글 학습 자료를 쉽고 빠르게 빈칸
              콘텐츠로 만들어 줍니다. 학생과 공유할 수 있고, 누구에게나 공유할
              수 있습니다. 저장기간은 1년입니다.
            </Box>
            <Button
              style={{
                display: 'block',
                marginTop: '1rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                APIUtils.LoggerLog(
                  authReducer.user_id,
                  'keewit',
                  'info',
                  window.location.pathname,
                  'click_event_expand7',
                );
                setExpand7(prev => !prev);
              }}
            >
              Q. 글쓰기 대회를 도입하고 싶어요.
            </Button>
            <Box
              style={{
                display: expand7 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              키위티-키위런을 사용하면 글쓰기 대회를 위해서 추가 준비가
              필요없습니다. 다만, 학생과 글쓰기 대회 주최자 선생님의 연결을
              자동화하는 것이 편리합니다. 이때에는 고객센터에 요청하면 키위티
              계정을 자동연결 기능이 되는 인증번호로 변경하여 드립니다.
            </Box>
            <Button
              style={{
                display: 'block',
                marginTop: '1rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                APIUtils.LoggerLog(
                  authReducer.user_id,
                  'keewit',
                  'info',
                  window.location.pathname,
                  'click_event_expand8',
                );
                setExpand8(prev => !prev);
              }}
            >
              Q. 글공유 기능이 제공되나요?
            </Button>
            <Box
              style={{
                display: expand8 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              아직 제공되고 있지 않습니다. 대신에 글을 복사하는 기능이 제공되고
              있습니다. 선생님과 학생들 사이의 글 공유 기능은 2025년 3월부터
              제공될 예정입니다. 과제를 부여받은 학생들만 과제 기간동안 공유된
              이후에 사라지는 방식으로 제공될 예정입니다.
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            marginTop: '2rem',
            flex: '6',
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
          }}
        >
          <img src={imgMain5} alt="imgMain5" style={{width: '80%'}} />
        </Box>
      </Box>
      <FooterView />
    </Box>
  );
};
export default MainPage;
