import {Box, Button, TextField} from '@mui/material';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import React, {useState} from 'react';
import APIUtils from 'common/utils/APIUtils';
import HistoryTable from 'view/mypage/HistoryTable';
import FilePopup from 'view/popup/FileUpload';
import DeleteHistoryPopup from 'view/popup/DeleteHistoryConfirm';
import {useNavigate} from 'react-router-dom';
import NoticketPopup from 'view/popup/NoTicket';
import ApiLoading from 'view/common/ApiLoading2';
import ClipboardCopy from 'view/common/ClipboardCopy2';
import CryptoJS from 'crypto-js';
import ToastPopup from 'view/popup/ToastPopup';

export const FragmentView = () => {
  const authReducer = useSelector(state => state.authReducer);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [purchased, setPurchased] = useState(true);
  const [fileVisibility, setFileVisibility] = useState(false);
  const [originalHistoryTable, setOriginalHistoryTable] = useState([]);
  const [historyTable, setHistoryTable] = useState([]);
  const [historyStatus, setHistoryStatus] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [toastText, setToastText] = useState('');
  const [toastVisibility, setToastVisibility] = useState(false);

  const handleSearchText = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 50) {
      inputVal = inputVal.substring(0, 50);
    }
    setSearchText(inputVal);
    historyTableFilter(historyStatus, inputVal);
  };
  function fileCancel() {
    setFileVisibility(false);
  }
  //delete history confirm popup values
  const [deleteVisibility, setDeleteVisibility] = useState(false);
  const [deleteEvalID, setDeleteEvalID] = useState([]);
  function deleteCancel() {
    setDeleteVisibility(false);
  }
  function getHistoryInfo() {
    const getHistory = async () => {
      try {
        const response = await APIUtils.EvalHistory(authReducer.user_id);
        setOriginalHistoryTable(response.data.eval_history);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    getHistory();
  }
  function deleteHistory() {
    const deleteHistory = async () => {
      try {
        const response = await APIUtils.EvalHistoryDelete(deleteEvalID);
      } catch (err) {
        console.log(err);
      }
    };

    deleteHistory()
      .then(() => {
        getHistoryInfo();
      })
      .then(() => {
        setDeleteVisibility(false);
      });
  }
  function getStudentLink() {
    const encryptedID = CryptoJS.AES.encrypt(
      authReducer.user_id.toString(),
      process.env.REACT_APP_AES_KEY,
    )
      .toString()
      .replaceAll('/', '$keewi$');
    const link =
      process.env.REACT_APP_BASE_URI + '/writing/student/' + encryptedID;
    return link;
  }
  function handleCopyResult(text) {
    setToastText(text);
    setToastVisibility(true);
    setTimeout(() => {
      setToastVisibility(false);
    }, 2500);
  }
  function openModify(eval_id) {
    if (purchased) {
      APIUtils.LoggerLog(
        authReducer.user_id,
        'keewit',
        'info',
        window.location.pathname,
        'navigate_modify',
      );
      window.localStorage.removeItem('text');
      navigate('/modify', {
        state: {
          eval_id: eval_id,
        },
      });
    } else {
      setNoticketVisibility(true);
    }
  }
  function openWriting() {
    if (purchased) {
      APIUtils.LoggerLog(
        authReducer.user_id,
        'keewit',
        'info',
        window.location.pathname,
        'navigate_writing',
      );
      navigate('/writing', {
        state: {
          prev: 'mypage',
        },
      });
    } else {
      setNoticketVisibility(true);
    }
  }
  function openFileUpload() {
    if (purchased) {
      setFileVisibility(true);
    } else {
      setNoticketVisibility(true);
    }
  }
  function getPassInfo() {
    const passResult = async () => {
      try {
        const response = await APIUtils.PassCheck(authReducer.user_id);
        if (response.status == 200 && response.data.ret_code == 1000) {
          if (response.data.status == 'none') {
            setPurchased(false);
          } else {
            setPurchased(true);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    passResult();
  }

  const [noticketVisibility, setNoticketVisibility] = useState(false);
  function noticketCancel() {
    setNoticketVisibility(false);
  }
  function noticketComplete() {
    setNoticketVisibility(false);
    navigate('/purchase');
  }

  function makePDF(eval_id) {
    const makePDFApi = async () => {
      try {
        const response = await APIUtils.CreateEvalReportUrl(eval_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    makePDFApi().then(() => {
      getHistoryInfo();
    });
  }
  function sendPDF(pdf_url) {
    window.open(pdf_url);
  }

  function historyTableFilter(state, searchValue) {
    if (originalHistoryTable == undefined || originalHistoryTable.length == 0) {
      setHistoryTable([]);
      return;
    }
    let filtered = [...originalHistoryTable];
    if (searchValue != undefined && searchValue.trim() != '') {
      filtered = filtered.filter(item => {
        return (
          (item.student_name != undefined &&
            item.student_name.includes(searchValue)) ||
          (item.title != undefined && item.title.includes(searchValue))
        );
      });
    } else if (state == 1) {
      const today = new Date();
      const date = new Date(today);
      date.setMonth(date.getMonth() - 1);
      filtered = filtered.filter(item => new Date(item.eval_time) - date >= 0);
    } else if (state == 2) {
      const today = new Date();
      const date = new Date(today);
      date.setMonth(date.getMonth() - 3);
      filtered = filtered.filter(item => new Date(item.eval_time) - date >= 0);
    } else if (state == 3) {
      const today = new Date();
      const date = new Date(today);
      date.setMonth(date.getMonth() - 6);
      filtered = filtered.filter(item => new Date(item.eval_time) - date >= 0);
    } else if (state == 4) {
      const today = new Date();
      const date = new Date(today);
      date.setMonth(date.getMonth() - 12);
      filtered = filtered.filter(item => new Date(item.eval_time) - date >= 0);
    }

    filtered.sort((a, b) => {
      const dateA = a.last_modify_time == '' ? a.eval_time : a.last_modify_time;
      const dateB = b.last_modify_time == '' ? b.eval_time : b.last_modify_time;
      return new Date(dateB) - new Date(dateA);
    });
    setHistoryTable(filtered);
  }

  useEffect(() => {
    getHistoryInfo();
    getPassInfo();
  }, [fileVisibility]);
  useEffect(() => {
    historyTableFilter(historyStatus, searchText);
    const pdfTimer = setInterval(() => {
      const makingCount = historyTable.filter(
        val => val.last_pdf_url == 'making',
      ).length;
      getHistoryInfo();
      if (makingCount == 0) {
        clearInterval(pdfTimer);
      }
    }, 10000);
    return () => {
      clearInterval(pdfTimer);
    };
  }, [originalHistoryTable]);
  if (loading) {
    return <ApiLoading />;
  }
  return (
    <Box style={{minWidth: '30rem'}}>
      <FilePopup visibility={fileVisibility} cancelEvent={fileCancel} />
      <ToastPopup text={toastText} visibility={toastVisibility} />
      <DeleteHistoryPopup
        visibility={deleteVisibility}
        count={deleteEvalID.length}
        completeEvent={deleteHistory}
        cancelEvent={deleteCancel}
      />
      <NoticketPopup
        visibility={noticketVisibility}
        cancelEvent={noticketCancel}
        completeEvent={noticketComplete}
      />
      <Box style={{fontWeight: 'bold', fontSize: '1.375rem'}}>
        개별 평가 관리
      </Box>
      <Box
        style={{
          backgroundColor: '#E6E8EB',
          width: '100%',
          height: '2px',
          marginTop: '1.3rem',
          marginBottom: '1.75rem',
        }}
      />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            color: '#87929D',
            fontSize: '0.875rem',
          }}
        >
          <Box>직접 입력하여 평가 리포트를 작성할 수 있습니다. </Box>
          <Box>
            제출 링크를 복사하면 키위티-키위런 없이도 글을 제출받을 수 있습니다.
          </Box>
          <Box>종료된 과제는 1년동안 저장됩니다. </Box>
        </Box>

        <Box style={{display: 'flex', gap: '0.8rem'}}>
          <Button
            style={{
              whiteSpace: 'nowrap',
              fontSize: '0.875rem',
              fontWeight: 'bold',
              height: '3rem',
              color: 'white',
              backgroundColor: '#35D2BB',
              border: '1px solid var(--01-gray-06, #35D2BB)',
              borderRadius: '6.25rem',
              padding: '0rem 1rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => openWriting()}
          >
            새 글 평가
          </Button>
          <Button
            style={{
              whiteSpace: 'nowrap',
              fontSize: '0.875rem',
              fontWeight: 'bold',
              height: '3rem',
              color: 'white',
              backgroundColor: '#279EFF',
              border: '1px solid var(--01-gray-06, #279EFF)',
              borderRadius: '6.25rem',
              padding: '0rem 1rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => openFileUpload()}
          >
            파일 업로드
          </Button>
          <Button
            style={{
              whiteSpace: 'nowrap',
              fontSize: '0.875rem',
              fontWeight: 'bold',
              height: '3rem',
              color: 'white',
              backgroundColor: '#F6F6F6',
              border: '1px solid var(--01-gray-06, #898989)',
              borderRadius: '6.25rem',
              padding: '0rem 1rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              width: '7rem',
            }}
          >
            <ClipboardCopy
              text={'제출 링크 복사'}
              link={getStudentLink()}
              resultEvent={handleCopyResult}
              buttonType={0}
            />
          </Button>
        </Box>
      </Box>
      <Box
        style={{
          marginTop: '4rem',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Button
            style={{
              width: '4rem',
              height: '2rem',
              borderRadius: '0px',
              backgroundColor: 'white',
              color: '#636c73',
              border: '3px solid #f9f9f9',
              boxShadow: '0px 0px 10px 3px #f9f9f9',
              fontSize: '0.75rem',
            }}
            onClick={() => {
              setHistoryStatus(1);
              historyTableFilter(1, searchText);
            }}
          >
            1개월
          </Button>
          <Button
            style={{
              marginLeft: '3px',
              width: '4rem',
              height: '2rem',
              borderRadius: '0px',
              backgroundColor: 'white',
              color: '#636c73',
              border: '3px solid #f9f9f9',
              boxShadow: '0px 0px 10px 3px #f9f9f9',
              fontSize: '0.75rem',
            }}
            onClick={() => {
              setHistoryStatus(2);
              historyTableFilter(2, searchText);
            }}
          >
            3개월
          </Button>
          <Button
            style={{
              marginLeft: '3px',
              width: '4rem',
              height: '2rem',
              borderRadius: '0px',
              backgroundColor: 'white',
              color: '#636c73',
              border: '3px solid #f9f9f9',
              boxShadow: '0px 0px 10px 3px #f9f9f9',
              fontSize: '0.75rem',
            }}
            onClick={() => {
              setHistoryStatus(3);
              historyTableFilter(3, searchText);
            }}
          >
            6개월
          </Button>
          <Button
            style={{
              marginLeft: '3px',
              width: '4rem',
              height: '2rem',
              borderRadius: '0px',
              backgroundColor: 'white',
              color: '#636c73',
              border: '3px solid #f9f9f9',
              boxShadow: '0px 0px 10px 3px #f9f9f9',
              fontSize: '0.75rem',
            }}
            onClick={() => {
              setHistoryStatus(4);
              historyTableFilter(4, searchText);
            }}
          >
            1년
          </Button>
        </Box>
        <Box>
          <TextField
            autoComplete="off"
            size="small"
            variant="outlined"
            sx={{
              backgroundColor: 'white',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid black',
                },
                '&:hover fieldset': {
                  border: '1px solid black',
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid black',
                },
              },
            }}
            label={searchText == '' ? '🔍 검색(제목/이름)' : ''}
            InputLabelProps={{
              style: {
                color: 'rgba(108, 108, 108, 0.721)',
                fontSize: 'calc(0.3rem + 0.5vw)',
              },
              shrink: false,
            }}
            InputProps={{style: {fontSize: 'calc(0.3rem + 0.5vw)'}}}
            value={searchText}
            onChange={handleSearchText}
          ></TextField>
        </Box>
      </Box>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '1rem',
        }}
      >
        <HistoryTable
          history={historyTable}
          modifyEvent={openModify}
          makeEvent={makePDF}
          sendEvent={sendPDF}
          multiDeleteEvent={itemList => {
            if (itemList.length == 0) {
              return;
            }
            setDeleteEvalID(itemList.map(i => historyTable[i].eval_id));
            setDeleteVisibility(true);
          }}
        />
      </Box>
    </Box>
  );
};
export default FragmentView;
