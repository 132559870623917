import {Box, Button, TextField} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {modifyAccountInfo, removeLogin} from 'common/reducer/auth';
import React, {useState} from 'react';
import APIUtils from 'common/utils/APIUtils';
import logoGoogle from 'asset/image/logo_google.png';
import logoNaver from 'asset/image/logo_naver.png';
import logoKakao from 'asset/image/logo_kakao.png';
import emojiRegex from 'emoji-regex';
import AccountLeavePopup from 'view/popup/AccountLeave';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

export const FragmentView = props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authReducer = useSelector(state => state.authReducer);

  const [accountLeaveVisibility, setAccountLeaveVisibility] = useState(false);
  function accountLeaveCancel() {
    setAccountLeaveVisibility(false);
  }
  function accountLeaveComplete() {
    APIUtils.LoggerLog(
      authReducer.user_id,
      'keewit',
      'info',
      window.location.pathname,
      'click_event_account_remove',
    );
    setAccountLeaveVisibility(false);
    dispatch(removeLogin());
    navigate('/');
  }
  const [nameText, setNameText] = useState(authReducer.export_name);
  const handleName = event => {
    let inputVal = event.target.value;

    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 15) {
        inputVal = inputVal.substring(0, 15);
      }
      setNameText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };
  function saveSetting() {
    const modifyResult = async (
      user_id,
      export_name,
      export_email,
      grade_in_charge,
      brand_bar_url,
    ) => {
      try {
        const response = await APIUtils.AccountModify(
          user_id,
          export_name,
          export_email,
          grade_in_charge,
          brand_bar_url,
        );
        if (response.data.ret_code == 1000) {
          alert('저장되었습니다.');
          dispatch(
            modifyAccountInfo({
              export_name: export_name,
              export_email: export_email,
              grade_in_charge: grade_in_charge,
            }),
          );
        }
      } catch (err) {
        console.log(err);
      }
    };
    modifyResult(
      authReducer.user_id,
      nameText,
      authReducer.user_email,
      authReducer.grade_in_charge,
      '',
    );
  }
  function getAccountLogo() {
    if (authReducer.path == 'google') {
      return (
        <img
          src={logoGoogle}
          alt={'logoGoogle'}
          style={{
            height: '30px',
            borderRadius: '30px',
            boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.15)',
          }}
        />
      );
    } else if (authReducer.path == 'naver') {
      return (
        <img
          src={logoNaver}
          alt={'logoNaver'}
          style={{
            height: '30px',
            borderRadius: '30px',
            boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.15)',
          }}
        />
      );
    } else if (authReducer.path == 'kakao') {
      return (
        <img
          src={logoKakao}
          alt={'logoKakao'}
          style={{
            height: '30px',
            borderRadius: '30px',
            boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.15)',
          }}
        />
      );
    }
    return <></>;
  }
  return (
    <Box style={{marginTop: '3rem'}}>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center', // 수직 중앙 정렬
          justifyContent: 'start', // 요소 간 간격 조정
          marginTop: '2rem', // 전체 상단 여백
          marginBottom: '1rem', // 전체 하단 여백
          gap: '1rem', // 요소 간 간격
        }}
      >
        <Box
          style={{
            fontSize: '1.125rem', // 원래 폰트 크기로 복구
            fontWeight: 'bold',
            whiteSpace: 'nowrap', // 텍스트 줄바꿈 방지
          }}
        >
          선생님 이름
        </Box>
        <Box style={{position: 'relative', width: '300px'}}>
          <TextField
            autoComplete="off"
            variant="outlined"
            label={
              nameText === ''
                ? '키위런의 선생님 이름과 평가 리포트에 OOO 선생님으로 출력됩니다.'
                : ''
            }
            InputProps={{
              style: {
                backgroundColor: '#F7F8FA',
                border: '1px solid #E9EBED',
                fontSize: '1rem', // 입력 필드 글씨 크기 유지
                borderRadius: '0.5rem', // 테두리 둥글기 조정
                height: '2.5rem', // 필드 높이
              },
            }}
            InputLabelProps={{
              style: {
                color: '#ADB4BA',
                fontSize: '0.875rem', // 라벨 글씨 크기 유지
              },
              shrink: false,
            }}
            value={nameText}
            onChange={handleName}
            style={{
              width: '100%', // 입력창 너비 설정
            }}
          />
          <Box
            style={{
              position: 'absolute',
              bottom: '0.5rem', // 입력창 아래에 표시
              right: '0.5rem',
              fontSize: '0.75rem',
              color: '#ADB4BA',
            }}
          >
            {nameText.length}/15
          </Box>
        </Box>
        <Button
          style={{
            color: '#00C500',
            fontSize: '0.875rem',
            border: '1px solid #00C500',
            borderRadius: '1.625rem',
            padding: '0.7rem 0rem',
            width: '6rem',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          onClick={saveSetting}
        >
          이름 변경
        </Button>
      </Box>
      <Box
        style={{
          marginTop: '1rem',
          fontSize: '1rem',
          color: '#2F3941',
        }}
      >
        키위런의 선생님 이름과 평가 리포트에{' '}
        <span style={{fontWeight: 'bold', fontSize: '1.2rem'}}>
          {nameText || 'OOO'} 선생님
        </span>
        으로 출력됩니다.
      </Box>
      <Box
        style={{
          marginTop: '6rem', // 전체 상단 여백
        }}
      >
        <AccountLeavePopup
          visibility={accountLeaveVisibility}
          sentEmail={authReducer.user_email}
          completeEvent={accountLeaveComplete}
          cancelEvent={accountLeaveCancel}
        />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            gap: '6rem',
          }}
        >
          <Box style={{display: 'flex', alignItems: 'center'}}>
            <Box>{getAccountLogo()}</Box>
            <Box style={{marginLeft: '1rem', fontSize: '1rem'}}>
              {authReducer.user_email}
            </Box>
          </Box>
          <Button
            style={{
              color: 'black',
              fontSize: '0.75rem',
              fontWeight: 'bold',
              borderRadius: '2rem',
              border: '1.5px solid #e9ebed',
              backgroundColor: '#e9ebed',
              padding: '0.8rem 1.5rem',
              whiteSpace: 'nowrap',
            }}
            onClick={() => {
              setAccountLeaveVisibility(true);
              props.getPassInfo();
            }}
          >
            계정 탈퇴
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
FragmentView.propTypes = {
  getPassInfo: PropTypes.func,
};
export default FragmentView;
