import {Box, Button} from '@mui/material';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import React, {useState} from 'react';
import Pagination from 'view/mypage/Pagination';
import APIUtils from 'common/utils/APIUtils';
import IconPdf from 'asset/imageV2/icon_pdf.png';
import IconDownload from 'asset/imageV2/icon_download.png';
import ImageAdmin1 from 'asset/imageV2/image_admin1.png';
import ImageAdmin2 from 'asset/imageV2/image_admin2.png';
import guidePDF1 from 'asset/pdf/guide1.pdf';
import guidePDF2 from 'asset/pdf/guide2.pdf';
import guidePDF3 from 'asset/pdf/guide3.pdf';
import guidePDF4 from 'asset/pdf/guide4.pdf';
import guidePDF5 from 'asset/pdf/guide5.pdf';
import TicketView from './TicketView1';

export const FragmentView = () => {
  const authReducer = useSelector(state => state.authReducer);
  const countPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const [groupName, setGroupName] = useState('');
  const [groupCode, setGroupCode] = useState('');
  const [groupAddress, setGroupAddress] = useState('');
  const [groupAdminEmail, setGroupAdminEmail] = useState('');
  const [teacherCount, setTeacherCount] = useState(0);
  const [studentCount, setStudentCount] = useState(0);
  const [groupTicket, setGroupTicket] = useState([]);

  function paginationClickEvent(page) {
    setCurrentPage(page);
  }
  function codeReset() {
    const getInfo = async () => {
      try {
        const response = await APIUtils.AdminCodeReset(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getInfo().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        getAdminInfo();
      }
    });
  }

  function getAdminInfo() {
    const getInfo = async () => {
      try {
        const response = await APIUtils.AdminView(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getInfo().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        setGroupCode(res.data.group_code);
        setTeacherCount(res.data.teacher_count);
        setStudentCount(res.data.student_count);
        setGroupAddress(res.data.group_location);
      }
    });
  }
  function getGroupInfo() {
    const getInfo = async () => {
      try {
        const response = await APIUtils.GroupInfo(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getInfo().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        setGroupName(res.data.group_name);
        setGroupAdminEmail(res.data.group_admin_email);

        setTotalPage(
          Math.floor((res.data.group_inventory.length - 1) / countPerPage + 1),
        );
        setGroupTicket(
          res.data.group_inventory.sort(
            (a, b) =>
              new Date(b.group_pass_log_time) - new Date(a.group_pass_log_time),
          ),
        );
      }
    });
  }
  function downloadGuidePDF1() {
    const pdfBlob = new Blob([guidePDF1], {type: 'application/pdf'});
    const url = window.URL.createObjectURL(pdfBlob);
    const a = document.createElement('a');
    a.href = guidePDF1;

    a.download = '[키위티] 사용설명서(그룹사용자).pdf';
    document.body.appendChild(a);
    a.click();
    setTimeout(_ => {
      window.URL.revokeObjectURL(url);
    }, 60000);
    a.remove();
  }
  function downloadGuidePDF2() {
    const pdfBlob = new Blob([guidePDF2], {type: 'application/pdf'});
    const url = window.URL.createObjectURL(pdfBlob);
    const a = document.createElement('a');
    a.href = guidePDF2;

    a.download = '[키위런] 사용설명서.pdf';
    document.body.appendChild(a);
    a.click();
    setTimeout(_ => {
      window.URL.revokeObjectURL(url);
    }, 60000);
    a.remove();
  }
  function downloadGuidePDF3() {
    const pdfBlob = new Blob([guidePDF3], {type: 'application/pdf'});
    const url = window.URL.createObjectURL(pdfBlob);
    const a = document.createElement('a');
    a.href = guidePDF3;

    a.download = '[키위티] 2024년 1학기 활용 사례.pdf';
    document.body.appendChild(a);
    a.click();
    setTimeout(_ => {
      window.URL.revokeObjectURL(url);
    }, 60000);
    a.remove();
  }
  function downloadGuidePDF4() {
    const pdfBlob = new Blob([guidePDF4], {type: 'application/pdf'});
    const url = window.URL.createObjectURL(pdfBlob);
    const a = document.createElement('a');
    a.href = guidePDF4;

    a.download = '[키위AI] 글쓰기 평가 KEEwi 엔진.pdf';
    document.body.appendChild(a);
    a.click();
    setTimeout(_ => {
      window.URL.revokeObjectURL(url);
    }, 60000);
    a.remove();
  }
  function downloadGuidePDF5() {
    const pdfBlob = new Blob([guidePDF5], {type: 'application/pdf'});
    const url = window.URL.createObjectURL(pdfBlob);
    const a = document.createElement('a');
    a.href = guidePDF5;

    a.download = '[키위티] 그룹관리자 사용설명서.pdf';
    document.body.appendChild(a);
    a.click();
    setTimeout(_ => {
      window.URL.revokeObjectURL(url);
    }, 60000);
    a.remove();
  }
  useEffect(() => {
    getAdminInfo();
    getGroupInfo();
  }, []);
  return (
    <Box style={{width: '100%', maxWidth: '80rem'}}>
      <Box style={{fontWeight: 'bold', fontSize: '2rem', color: '#4b4d4c'}}>
        {groupName} <font color="#68737d"> 관리자</font>
      </Box>
      <Box
        style={{
          marginTop: '0.5rem',
          color: '#87929D',
          fontSize: '0.875rem',
          flex: 1,
          overflow: 'hidden',
          maxHeight: '3rem',
          marginBottom: '0.5rem',
        }}
      >
        그룹에 소속된 선생님을 내보내기 하거나{' '}
        <font color="#ff9900">그룹코드를 변경</font>할 수 있습니다. 그룹코드가
        변경되어도 이전에 소속된 선생님은 그대로 유지됩니다.
      </Box>
      <Box
        style={{
          marginTop: '2rem',
          display: 'flex',
          gap: '2rem',
        }}
      >
        <Box style={{flex: 3}}>
          <Box
            style={{
              backgroundColor: 'white',
              boxShadow: '0px 5px 5px 0px #cdcdcd',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              style={{
                margin: '2rem 0rem 0rem 2rem',
                color: '#666972',
                fontSize: '1.2rem',
                fontWeight: 'bold',
              }}
            >
              그룹 정보
            </Box>
            <Box style={{margin: '1.5rem 0rem 0rem 2rem'}}>
              {'\u2022'} 기관명 : {' ' + groupName}
            </Box>
            <Box style={{margin: '0.5rem 0rem 0rem 2rem'}}>
              {'\u2022'} 소재지 : {' ' + groupAddress}
            </Box>
            <Box style={{margin: '0.5rem 0rem 0rem 2rem'}}>
              {'\u2022'} 그룹 관리자
            </Box>
            <Box style={{margin: '0.2rem 0rem 0rem 2.5rem'}}>
              이름 :{' ' + authReducer.export_name}
            </Box>
            <Box style={{margin: '0.2rem 0rem 0rem 2.5rem'}}>
              연락처 :{' ' + groupAdminEmail}
            </Box>
            <Box
              style={{
                fontSize: '0.75rem',
                color: '#666972',
                textAlign: 'end',
                margin: '0.5rem 0.5rem  0.5rem  0rem',
              }}
            >
              ※ 그룹 정보 변경이 필요하시면, 고객센터로 연락주세요.
            </Box>
          </Box>
        </Box>
        <Box style={{flex: 1}}>
          <Box
            style={{
              backgroundColor: 'white',
              boxShadow: '0px 5px 5px 0px #cdcdcd',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'start',
            }}
          >
            <Box
              style={{
                color: '#666972',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                marginLeft: '2rem',
              }}
            >
              그룹 코드
            </Box>
            <Box
              style={{
                width: '100%',
                marginTop: '1.5rem',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '2rem',
                color: '#30C0AB',
              }}
            >
              {groupCode}
            </Box>
            <Box
              style={{
                marginTop: '1.5rem',
                display: 'flex',
                gap: '0.5rem',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Button
                style={{
                  backgroundColor: '#ff9900',
                  borderRadius: '1.5rem',
                  color: 'white',
                  padding: 0,
                }}
                onClick={() => {
                  codeReset();
                }}
              >
                변경
              </Button>
              {/* <Button
                style={{
                  backgroundColor: '#30C0AB',
                  borderRadius: '1.5rem',
                  color: 'white',
                  padding: 0,
                }}
              >
                복사
              </Button> */}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          marginTop: '3rem',
          display: 'flex',
          gap: '2rem',
        }}
      >
        <Box style={{flex: 1}}>
          <Box
            style={{
              backgroundColor: 'white',
              boxShadow: '0px 5px 5px 0px #cdcdcd',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box
              style={{
                color: '#666972',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                margin: '1rem 0rem 0rem 1rem',
              }}
            >
              소속된 선생님 (키위티)
            </Box>
            <Box
              style={{
                fontSize: '1rem',
                margin: '1rem 0rem 0rem 1rem',
                textAlign: 'center',
              }}
            >
              <span
                style={{
                  fontSize: '3rem',
                  fontWeight: 'bold',
                  marginRight: '1rem',
                }}
              >
                {teacherCount}
              </span>
              명
            </Box>
            <Box style={{display: 'flex', justifyContent: 'end'}}>
              <img
                src={ImageAdmin1}
                alt="ImageAdmin1"
                style={{height: '6rem'}}
              />
            </Box>
          </Box>
        </Box>
        <Box style={{flex: 1}}>
          <Box
            style={{
              backgroundColor: 'white',
              boxShadow: '0px 5px 5px 0px #cdcdcd',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box
              style={{
                color: '#666972',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                margin: '1rem 0rem 0rem 1rem',
              }}
            >
              소속된 학생 (키위런)
            </Box>
            <Box
              style={{
                fontSize: '1rem',
                margin: '1rem 0rem 0rem 1rem',
                textAlign: 'center',
              }}
            >
              <span
                style={{
                  fontSize: '3rem',
                  fontWeight: 'bold',
                  marginRight: '1rem',
                }}
              >
                {studentCount}
              </span>
              명
            </Box>
            <Box style={{display: 'flex', justifyContent: 'start'}}>
              <img
                src={ImageAdmin2}
                alt="ImageAdmin2"
                style={{height: '7rem'}}
              />
            </Box>
          </Box>
        </Box>
        <Box style={{flex: 2}}>
          <Box
            style={{
              backgroundColor: 'white',
              boxShadow: '0px 5px 5px 0px #cdcdcd',
              // width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              style={{
                margin: '1rem 0rem 0rem 2rem',
                color: '#666972',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                src={IconPdf}
                alt="IconPdf"
                style={{marginRight: '0.5rem'}}
              />{' '}
              연수 자료
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '1.5rem 1rem 1.5rem 2rem',
                fontSize: '1rem',
                gap: '1rem',
              }}
            >
              <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                <Box> {'\u2022'} [키위티] 사용설명서(그룹사용자).pdf</Box>
                <Button
                  style={{
                    backgroundColor: '#30C0AB',
                    fontSize: '1rem',
                    color: 'white',
                    whiteSpace: 'nowrap',
                    borderRadius: '1rem',
                    padding: '0rem 1rem',
                    minWidth: '7rem',
                  }}
                  onClick={() => {
                    downloadGuidePDF1();
                  }}
                >
                  <img
                    src={IconDownload}
                    alt="IconDownload"
                    style={{height: '1rem'}}
                  />{' '}
                  다운로드
                </Button>
              </Box>
              <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                <Box> {'\u2022'} [키위런] 사용설명서.pdf</Box>
                <Button
                  style={{
                    backgroundColor: '#30C0AB',
                    fontSize: '1rem',
                    color: 'white',
                    whiteSpace: 'nowrap',
                    borderRadius: '1rem',
                    padding: '0rem 1rem',
                    minWidth: '7rem',
                  }}
                  onClick={() => {
                    downloadGuidePDF2();
                  }}
                >
                  <img
                    src={IconDownload}
                    alt="IconDownload"
                    style={{height: '1rem'}}
                  />{' '}
                  다운로드
                </Button>
              </Box>
              <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                <Box> {'\u2022'} [키위티] 2024년 1학기 활용 사례</Box>
                <Button
                  style={{
                    backgroundColor: '#30C0AB',
                    fontSize: '1rem',
                    color: 'white',
                    whiteSpace: 'nowrap',
                    borderRadius: '1rem',
                    padding: '0rem 1rem',
                    minWidth: '7rem',
                  }}
                  onClick={() => {
                    downloadGuidePDF3();
                  }}
                >
                  <img
                    src={IconDownload}
                    alt="IconDownload"
                    style={{height: '1rem'}}
                  />{' '}
                  다운로드
                </Button>
              </Box>
              <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                <Box> {'\u2022'} [키위AI] 글쓰기 평가 KEEwi 엔진.pdf</Box>
                <Button
                  style={{
                    backgroundColor: '#30C0AB',
                    fontSize: '1rem',
                    color: 'white',
                    whiteSpace: 'nowrap',
                    borderRadius: '1rem',
                    padding: '0rem 1rem',
                    minWidth: '7rem',
                  }}
                  onClick={() => {
                    downloadGuidePDF4();
                  }}
                >
                  <img
                    src={IconDownload}
                    alt="IconDownload"
                    style={{height: '1rem'}}
                  />{' '}
                  다운로드
                </Button>
              </Box>
              <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                <Box> {'\u2022'} [키위티] 그룹관리자 사용설명서.pdf</Box>
                <Button
                  style={{
                    backgroundColor: '#30C0AB',
                    fontSize: '1rem',
                    color: 'white',
                    whiteSpace: 'nowrap',
                    borderRadius: '1rem',
                    padding: '0rem 1rem',
                    minWidth: '7rem',
                  }}
                  onClick={() => {
                    downloadGuidePDF5();
                  }}
                >
                  <img
                    src={IconDownload}
                    alt="IconDownload"
                    style={{height: '1rem'}}
                  />{' '}
                  다운로드
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          margin: '3rem 0rem 1rem 0rem',
          backgroundColor: 'white',
          boxShadow: '0px 5px 5px 0px #cdcdcd',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          paddingBottom: '2rem',
        }}
      >
        <Box
          style={{
            color: '#666972',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            margin: '2rem 2rem 0rem 2rem',
          }}
        >
          이용권 보유 내역
        </Box>
        <Box
          style={{
            marginTop: '1rem',
            display: 'flex',
            justifyContent: 'space-evenly',
            paddingBottom: '0.7rem',
            textAlign: 'center',
            fontWeight: 'bold',
            width: '80%',
          }}
        >
          <Box style={{flex: 1}}>발생일</Box>
          <Box style={{flex: 2}}>사용 기간</Box>
          <Box style={{flex: 1}}>키위티 계정 수</Box>
          <Box style={{flex: 1}}>키위런 계정 수</Box>
          <Box style={{flex: 1}}>상태</Box>
        </Box>
        {groupTicket
          .filter((value, index) => {
            if (Math.floor(index / countPerPage) + 1 == currentPage) {
              return true;
            }
            return false;
          })
          .map((value, index) => {
            return <TicketView key={'ticket' + index} ticket_info={value} />;
          })}
      </Box>
      <Pagination
        totalPage={totalPage}
        currentPage={currentPage}
        clickEvent={paginationClickEvent}
      />
    </Box>
  );
};

export default FragmentView;
